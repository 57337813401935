import { useLocation } from '@reach/router';
import { useCallback } from 'react';

import { useLoginAppUrl } from '@xing-com/crate-xinglet';

export const useLoginWithJobDestUrl = (): string => {
  const location = useLocation();
  const loginAppUrl = useLoginAppUrl();
  const pathname = `/${location.pathname}`;

  return `${loginAppUrl}?dest_url=${encodeURIComponent(pathname)}`;
};

export const useRedirectToLoginWithJobDestUrl = (): (() => void) => {
  const loginAppUrl = useLoginWithJobDestUrl();

  return useCallback(() => {
    window?.location.assign(loginAppUrl);
  }, [loginAppUrl]);
};

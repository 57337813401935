import type { TemplateData } from '@xing-com/crate-common-graphql-types';

import { VideoConsentPlaceholder } from '../../no-consent-message';
import { ShowMore } from '../../show-more';
import { DESCRIPTION_PREVIEW_HEIGHT } from '../constants';

import { Image } from './image';
import { Section } from './section';
import { SocialLinks } from './social-links';
import { Video } from './video';

type Props = {
  jobId: string;
  description: TemplateData;
  externalContentConsent: boolean;
  skipShowMore: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
};

type TemplateDataContentProps = {
  jobId: string;
  description: TemplateData;
  externalContentConsent: boolean;
};

const TemplateDataContent: React.FC<TemplateDataContentProps> = ({
  jobId,
  description,
  externalContentConsent,
}) => {
  const {
    sectionTitleColor,
    headerImage,
    genericDescription,
    companyDescriptionTitle,
    companyDescriptionContent,
    responsibilityTitle,
    responsibilityContent,
    skillsTitle,
    skillsContent,
    weOfferTitle,
    weOfferContent,
    contactInfoTitle,
    contactInfoContent,
    footerImage,
    videoUrls,
    socialUrls,
  } = description;

  return (
    <>
      {headerImage ? (
        <Image src={headerImage} data-testid="header-image" />
      ) : null}
      {genericDescription ? (
        <Section jobId={jobId} content={genericDescription} />
      ) : null}
      {companyDescriptionContent ? (
        <Section
          jobId={jobId}
          title={companyDescriptionTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={companyDescriptionContent}
        />
      ) : null}
      {responsibilityContent ? (
        <Section
          jobId={jobId}
          title={responsibilityTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={responsibilityContent}
        />
      ) : null}
      {skillsContent ? (
        <Section
          jobId={jobId}
          title={skillsTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={skillsContent}
        />
      ) : null}
      {weOfferContent ? (
        <Section
          jobId={jobId}
          title={weOfferTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={weOfferContent}
        />
      ) : null}
      {contactInfoContent ? (
        <Section
          jobId={jobId}
          title={contactInfoTitle ?? undefined}
          titleColor={sectionTitleColor ?? undefined}
          content={contactInfoContent}
        />
      ) : null}
      {footerImage ? (
        <Image src={footerImage} data-testid="footer-image" />
      ) : null}
      {videoUrls?.vimeo ? (
        externalContentConsent ? (
          <Video type="vimeo" url={videoUrls.vimeo} jobId={jobId} />
        ) : (
          <VideoConsentPlaceholder url={videoUrls.vimeo} />
        )
      ) : null}
      {videoUrls?.youtube ? (
        externalContentConsent ? (
          <Video type="youtube" url={videoUrls.youtube} jobId={jobId} />
        ) : (
          <VideoConsentPlaceholder url={videoUrls.youtube} />
        )
      ) : null}
      {socialUrls ? (
        <SocialLinks jobId={jobId} socialUrls={socialUrls} />
      ) : null}
    </>
  );
};

export const TemplateDataDescription: React.FC<Props> = ({
  jobId,
  description,
  externalContentConsent,
  skipShowMore,
  onExpand,
  onCollapse,
}) => {
  return skipShowMore ? (
    <TemplateDataContent
      jobId={jobId}
      description={description}
      externalContentConsent={externalContentConsent}
    />
  ) : (
    <ShowMore
      previewHeight={DESCRIPTION_PREVIEW_HEIGHT}
      expandLabelKey={'JOBS_JOB_DETAILS_DESCRIPTION_SHOW_MORE'}
      collapseLabelKey={'JOBS_JOB_DETAILS_DESCRIPTION_SHOW_LESS'}
      onExpand={onExpand}
      onCollapse={onCollapse}
      hideOnDesktop
      centered
    >
      <TemplateDataContent
        jobId={jobId}
        description={description}
        externalContentConsent={externalContentConsent}
      />
    </ShowMore>
  );
};

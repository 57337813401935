import { useCallback, useMemo } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { useLoginWithJobDestUrl } from './use-redirect-to-login-with-job-dest-url';
import { useTrackApplyIntention } from './use-track-apply-intention';

type Props = { contactUserId?: string; jobId: string };

export const useApplyButtonMessageApplyProps = ({
  contactUserId,
  jobId,
}: Props): { onClick: () => void } => {
  const { isLoggedIn } = useLoginState();
  const loginPath = useLoginWithJobDestUrl();

  const trackApplyIntention = useTrackApplyIntention({
    applicationType: 'MessageApplication',
    jobId,
  });

  const onClick = useCallback(() => {
    trackApplyIntention();
  }, [trackApplyIntention]);

  const to = isLoggedIn
    ? `/chats/new/${contactUserId}?contextId=jobs_apply&entryPoint=jobs_job_apply_message`
    : loginPath;

  const messageProps = useMemo(() => ({ onClick, to }), [onClick, to]);

  return messageProps;
};

import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { truncate } from '@xing-com/crate-jobs-helpers';
import { useViewerData } from '@xing-com/hub';

import { useLoginWithJobDestUrl } from './use-redirect-to-login-with-job-dest-url';
import { useTrackApplyIntention } from './use-track-apply-intention';

type Props = {
  applyEmail?: string;
  code?: string;
  title?: string;
  jobId: string;
};

export const useApplyButtonEmailApplyProps = ({
  applyEmail,
  code,
  title,
  jobId,
}: Props): { to?: string; onClick: () => void } => {
  const intl = useIntl();
  const user = useViewerData().data?.user;
  const { isLoggedIn } = useLoginState();
  const trackApplyIntention = useTrackApplyIntention({
    applicationType: 'EmailApplication',
    jobId,
  });
  const loginPath = useLoginWithJobDestUrl();

  const buildEMailURI = useCallback(
    (applyEmail: string): string => {
      const subject = encodeURIComponent(
        intl.formatMessage(
          {
            id: `JOBS_JOB_DETAILS_APPLY_MAIL_${
              code ? 'SUBJECT_WITH_CODE' : 'SUBJECT'
            }`,
          },
          {
            posting_function: truncate(title ?? '', 50),
            posting_job_code: code,
          }
        )
      );
      const body = encodeURIComponent(
        intl.formatMessage(
          { id: 'JOBS_JOB_DETAILS_APPLY_MAIL_BODY' },
          { posting_function: title, name: user?.displayName }
        )
      );

      return `mailto:${applyEmail}?subject=${subject}&body=${body}`;
    },
    [code, intl, title, user?.displayName]
  );

  const onClick = useCallback(() => {
    trackApplyIntention();
  }, [trackApplyIntention]);

  const emailProps = useMemo(
    () => ({
      to: isLoggedIn ? buildEMailURI(applyEmail ?? '') : loginPath,
      onClick,
    }),
    [applyEmail, buildEMailURI, isLoggedIn, loginPath, onClick]
  );

  return emailProps;
};

import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

export const useLinkTracking = (jobId: string): RefObject<HTMLDivElement> => {
  const containerRef = useRef<HTMLDivElement>(null);
  const trackNwtEvent = useJobsNwtTracking();

  useEffect(() => {
    const trackLinkClick = (event: MouseEvent): void => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const target = event.target as HTMLElement;

      // track only clicks on links that are not mailto:
      if (
        target.tagName === 'A' &&
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (target as HTMLAnchorElement).href.startsWith('http')
      ) {
        trackNwtEvent({
          eventSchema: 'basic',
          page: 'jobs/detail',
          itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
          event: 'visited',
          element: 'job_description_link',
        });
      }
    };

    const containerDiv = containerRef.current;
    if (containerDiv) {
      containerDiv.addEventListener('click', trackLinkClick);
    }

    return () => {
      containerDiv?.removeEventListener('click', trackLinkClick);
    };
  });

  return containerRef;
};

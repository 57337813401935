import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useLocalStorage } from '@xing-com/crate-hooks-use-web-storage';
import { MenuItem } from '@xing-com/crate-jobs-components-menu-item';
import {
  JOBS_REPORTED_JOBS,
  SEARCH_ALERTS_MODAL_TYPES,
} from '@xing-com/crate-jobs-constants';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useJobManagerUrl } from '@xing-com/crate-jobs-hooks';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { unscrambleId } from '@xing-com/crate-utils-x1-data';
import { IconMore } from '@xing-com/icons';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';
import { space3XL, spaceL } from '@xing-com/tokens';

import { useModal, useSearchAlertModal, useShareJob } from '../../../../hooks';

import { ReportJobModal } from './report-job-modal';

const StyledMenu = styled(Menu)`
  margin-top: ${space3XL};
  white-space: nowrap;

  @media ${mediaWide} {
    margin-left: -${spaceL};
  }
`;

const DesktopMenuItem = styled(MenuItem)`
  display: none;

  @media ${mediaConfined} {
    display: flex;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

export type Action = 'REPORT_JOB' | 'SEARCH_ALERT' | 'SHARE_JOB' | 'MANAGE_JOB';

type Props = {
  job: JobDetails;
};

export const MoreMenuButton: React.FC<Props> = ({ job }) => {
  const popOver = usePopOver();
  const { formatMessage } = useIntl();
  const { track } =
    useTracking<InteractionTrackingEvent<{ PropTrackAction: string }>>();
  const trackNwt = useJobsNwtTracking();
  const { activeModal, toggleModal } = useModal();
  const { triggerShare } = useShareJob();
  const [reportedJobAds, setReportedJobAds] = useLocalStorage<string[]>(
    JOBS_REPORTED_JOBS,
    []
  );
  const { id: jobId, searchAlertFilters } = job;
  const { jobManagerUrl } = useJobManagerUrl(unscrambleId(jobId));
  const { openSearchAlertModal } = useSearchAlertModal({
    type: SEARCH_ALERTS_MODAL_TYPES.JOB_DETAILS,
    params: searchAlertFilters,
    isThirdPartyJob: job.redirectsToThirdPartyUrl,
  });
  const isJobReported = reportedJobAds.includes(jobId);

  const reportJobAd = (jobId: string): void => {
    setReportedJobAds([...reportedJobAds, jobId]);
  };

  const manageJob = (): void => {
    window.open(jobManagerUrl);
  };

  const handleOnPerformAction =
    (action: Action | 'REPORTED_JOB') =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();
      event.stopPropagation();
      popOver.handleHide();
      if (action === 'SEARCH_ALERT') openSearchAlertModal();
      if (action === 'SHARE_JOB') triggerShare();
      if (action === 'REPORT_JOB') toggleModal('REPORT_JOB');
      if (action === 'MANAGE_JOB') manageJob();
    };

  const handleOnMoreActionsClick = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    popOver.togglePopOver();
    if (!popOver.show) {
      track({
        type: 'interaction',
        event: 'PropTrackAction',
        PropTrackAction: 'jobs_meatball_menu_open',
      });
      trackNwt({
        event: 'clicked',
        eventSchema: 'basic',
        element: 'meatball_menu_open',
        page: 'jobs/detail',
        itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
      });
    }
  };

  return (
    <>
      <StyledButton
        size="small"
        sizeConfined="medium"
        variant="secondary"
        icon={IconMore}
        ref={popOver.triggerRef}
        // @ts-expect-error FIXME: SC6
        onClick={handleOnMoreActionsClick}
        aria-label={formatMessage({ id: 'JOBS_DETAIL_MENU_BUTTON_A11Y' })}
        data-testid="job-details-more-menu-button"
      />

      <StyledMenu
        isInFlow
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <MenuItem
          localeKey="JOBS_CREATE_SEARCH_ALERT"
          onClick={handleOnPerformAction('SEARCH_ALERT')}
        />
        <MenuItem
          localeKey="JOBS_JOB_DETAILS_SHARE_JOB"
          onClick={handleOnPerformAction('SHARE_JOB')}
        />

        <MenuItem
          localeKey={`JOBS_JOB_DETAILS_${
            isJobReported ? 'REPORTED_JOB' : 'REPORT_JOB'
          }`}
          onClick={handleOnPerformAction(
            isJobReported ? 'REPORTED_JOB' : 'REPORT_JOB'
          )}
        />
        {jobManagerUrl ? (
          <DesktopMenuItem
            localeKey="JOBS_JOB_DETAILS_MANAGE"
            onClick={handleOnPerformAction('MANAGE_JOB')}
          />
        ) : null}
      </StyledMenu>

      <ReportJobModal
        isShown={activeModal === 'REPORT_JOB'}
        toggle={toggleModal}
        jobId={jobId}
        reportJobAd={reportJobAd}
      />
    </>
  );
};

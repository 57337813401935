import styled from 'styled-components';

import type { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import type { JobDetails, ApplicationData } from '@xing-com/crate-jobs-helpers';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceS, spaceL } from '@xing-com/tokens';

import { ApplyButton } from '../actions/apply-button';
import { BookmarkButton } from '../actions/bookmark-button';
import { useMainActions } from '../actions/use-main-actions';

type Props = {
  application: ApplicationData;
  job: JobDetails;
  bookmarkState?: JobBookmarkState;
};

const ActionsContainer = styled.div<{ $hide: boolean }>`
  display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
  gap: ${spaceS};
  padding: ${spaceL} 0;
  width: 100%;

  @media ${mediaConfined} {
    padding: 0;
    width: auto;
  }
`;

export const StickyMainActions: React.FC<Props> = ({
  application,
  job,
  bookmarkState,
}) => {
  const { showApplyButton, showBookmarkButton } = useMainActions({
    application,
    job,
  });
  const hide = !showApplyButton && !showBookmarkButton;

  return (
    <ActionsContainer $hide={hide}>
      {showApplyButton ? (
        <ApplyButton application={application} isHeaderSticky />
      ) : null}
      {showBookmarkButton ? (
        <BookmarkButton job={job} bookmarkState={bookmarkState} iconOnly />
      ) : null}
    </ActionsContainer>
  );
};

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { SEARCH_ALERTS_MODAL_TYPES } from '@xing-com/crate-jobs-constants';
import type { SearchAlertFilters } from '@xing-com/crate-jobs-helpers';
import {
  useBreakpoints,
  useSearchAlertParams,
} from '@xing-com/crate-jobs-hooks';
import { IconNotificationCenter } from '@xing-com/icons';
import { IllustrationSpotBell } from '@xing-com/illustrations';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceM } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

import { useSearchAlertModal } from '../../hooks';

export const Container = styled.div`
  display: flex;
  gap: ${spaceM};

  @media ${mediaConfined} {
    justify-content: center;
  }

  @media print {
    display: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${spaceM};

  @media ${mediaConfined} {
    align-items: center;
    flex-direction: row;
    gap: ${spaceL};
  }
`;

export const IllustrationBell = styled(IllustrationSpotBell)`
  display: flex;
  align-self: flex-start;

  @media ${mediaConfined} {
    display: none;
  }
`;

type Props = {
  searchAlertFilters: SearchAlertFilters;
  isThirdPartyJob: boolean;
};

export const SearchAlertModule: React.FC<Props> = ({
  searchAlertFilters,
  isThirdPartyJob = false,
}) => {
  const { isMediaSmallOrTiny } = useBreakpoints();
  const { openSearchAlertModal } = useSearchAlertModal({
    type: SEARCH_ALERTS_MODAL_TYPES.JOB_DETAILS,
    params: searchAlertFilters,
    isThirdPartyJob,
  });
  const { keywords } = useSearchAlertParams() ?? {};

  return (
    <Container>
      <IllustrationBell width={64} height={64} />
      <Body>
        <Headline noMargin>
          {keywords ? (
            <FormattedMessage
              id="JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_KEYWORDS"
              values={{ keywords: <strong>{keywords}</strong> }}
            />
          ) : (
            <FormattedMessage
              id="JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_LOCATION"
              values={{ location: searchAlertFilters.location?.text }}
            />
          )}
        </Headline>
        <Button
          variant="secondary"
          size="small"
          icon={!isMediaSmallOrTiny ? IconNotificationCenter : undefined}
          sizeWide="medium"
          onClick={openSearchAlertModal}
          data-soft-login-fenced
        >
          <FormattedMessage id="JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_CTA" />
        </Button>
      </Body>
    </Container>
  );
};

import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { PropTrackActionData } from '@xing-com/crate-jobs-hooks';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { Hyperlink } from '@xing-com/hyperlink';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceL, spaceS, xdlColorText } from '@xing-com/tokens';

const StyledLink = styled(Hyperlink)`
  margin-top: ${spaceS};
  color: ${xdlColorText};
  font-weight: 700;

  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${xdlColorText};
  }

  @media ${mediaWideNavless} {
    margin-top: ${spaceL};
  }
`;

type Props = {
  link: string;
  jobId: string;
};

export const CultureLink: React.FC<Props> = ({ link, jobId }) => {
  const { track } =
    useTracking<InteractionTrackingEvent<PropTrackActionData>>();
  const trackNwtEvent = useJobsNwtTracking();

  const onKununuLinkClick = (): void => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_kununu_culture_further_info',
    });
    trackNwtEvent({
      event: 'visited',
      eventSchema: 'basic',
      page: 'jobs/details',
      itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
      element: 'kununu_culture_further_info',
    });
  };

  return (
    <StyledLink
      external
      href={`${link}?utm_source=xing_jobs&utm_medium=affiliate&utm_content=cta_culture_jobs&rfr=xjp_cta_culture`}
      // @ts-expect-error FIXME: SC6
      target="_blank"
      onClick={onKununuLinkClick}
      iconAfter
      size="small"
      rel="noopener"
    >
      <FormattedMessage id="JOBS_JOB_DETAILS_KUNUNU_CULTURE" />
    </StyledLink>
  );
};

import styled, { css } from 'styled-components';

import type { DescriptionFragment } from '@xing-com/crate-jobs-graphql';
import type { NwtTrackingProps } from '@xing-com/crate-jobs-tracking';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { spaceXXL } from '@xing-com/tokens';

import { useExternalContentConsent } from '../../hooks';
import { ModuleTitle } from '../module-title';

import { ExternalUrlDescription } from './external-url-description';
import { HtmlDescription } from './html-description';
import { LoadingPlaceholder } from './loading-placeholder';
import { PersonalwerkTracking } from './personalwerk-tracking';
import { TemplateDataDescription } from './template-data-description';

type DescriptionModuleProps = {
  jobId: string;
  description: DescriptionFragment;
  banner?: string;
  jobCode?: string | null;
  isThirdParty?: boolean;
};

type DescriptionProps = {
  jobId: string;
  description: DescriptionFragment;
  externalContentConsent: boolean;
  skipShowMore: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
};

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceXXL};
`;

const DescriptionBanner = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

const FILTER_BLUR_PX = '3px';

const BlurWrapper = styled.div<{ $isBlur: boolean }>`
  ${({ $isBlur }) =>
    $isBlur &&
    css`
      filter: blur(${FILTER_BLUR_PX});
      pointer-events: none;
      user-select: none;
      padding: 0 calc(2 * ${FILTER_BLUR_PX} + 1px); // set side padding to fit blur filter spreading inside the container
    `};
`;

const isPersonalwerkJob = (
  jobCode: string | null | undefined
): jobCode is string => {
  return typeof jobCode === 'string' && /personalwerk-\d+-\d+/.test(jobCode);
};

const Description: React.FC<DescriptionProps> = ({
  jobId,
  description,
  externalContentConsent,
  skipShowMore,
  onExpand,
  onCollapse,
}) => {
  switch (description.__typename) {
    case 'HtmlDescription':
      return (
        <HtmlDescription
          jobId={jobId}
          description={description}
          skipShowMore={skipShowMore}
          onExpand={onExpand}
          onCollapse={onCollapse}
        />
      );
    case 'ExternalUrlDescription':
      return (
        <ExternalUrlDescription
          url={description.url}
          proxyUrl={description.proxyUrl}
          externalContentConsent={externalContentConsent}
        />
      );
    case 'TemplateData':
      return (
        <TemplateDataDescription
          jobId={jobId}
          description={description}
          externalContentConsent={externalContentConsent}
          skipShowMore={skipShowMore}
          onExpand={onExpand}
          onCollapse={onCollapse}
        />
      );
    default:
      return 'UNSUPPORTED';
  }
};

export const DescriptionModule: React.FC<DescriptionModuleProps> = ({
  jobId,
  description,
  banner,
  jobCode,
  isThirdParty = false,
}) => {
  const { loading: externalContentConsentLoading, hasConsent } =
    useExternalContentConsent();
  const trackNwtEvent = useJobsNwtTracking();

  const baseNwtProps: NwtTrackingProps = {
    eventSchema: 'basic',
    page: 'jobs/detail',
    event: 'clicked',
    itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
  };

  if (externalContentConsentLoading) {
    return <LoadingPlaceholder />;
  }

  const onExpand = (): void => {
    trackNwtEvent({
      ...baseNwtProps,
      element: 'job_description_expand',
    });
  };

  const onCollapse = (): void => {
    trackNwtEvent({
      ...baseNwtProps,
      element: 'job_description_collapse',
    });
  };

  return (
    <DescriptionWrapper>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_DESCRIPTION_HEADLINE" noMargin />
      {hasConsent && isPersonalwerkJob(jobCode) ? (
        <NoSSR>
          <PersonalwerkTracking jobCode={jobCode} />
        </NoSSR>
      ) : null}
      <BlurWrapper $isBlur={isThirdParty}>
        {banner ? (
          <DescriptionBanner src={banner} alt="" aria-hidden="true" />
        ) : null}
        <Description
          jobId={jobId}
          description={description}
          externalContentConsent={hasConsent ?? false}
          skipShowMore={isThirdParty}
          onExpand={onExpand}
          onCollapse={onCollapse}
        />
      </BlurWrapper>
    </DescriptionWrapper>
  );
};

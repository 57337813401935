import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';

import { StatusBanner } from '@xing-com/banner';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import {
  xdlColorBackgroundTertiary,
  xdlColorControlFillQuaternary,
} from '@xing-com/tokens';

import { useExternalContentConsent } from '../../hooks';
import { ModuleTitle } from '../module-title';
import { VideoConsentPlaceholder } from '../no-consent-message';

type CompanyVideo = {
  jobId: string;
  videoUrl?: string;
};

type VideoTrackingBaseEvent = {
  PropJobsId: string;
};

type VideoLoadedEvent = VideoTrackingBaseEvent & {
  event: 'EventVideoLoaded';
  EventVideoLoaded: 1;
};

type VideoStartedEvent = VideoTrackingBaseEvent & {
  event: 'EventVideoStarted';
  EventVideoStarted: 1;
  PropActionOrigin: string;
};

type VideoFinishedEvent = VideoTrackingBaseEvent & {
  event: 'EventVideoFinished';
  EventVideoFinished: 1;
};

type VideoTrackingEvent =
  | VideoLoadedEvent
  | VideoStartedEvent
  | VideoFinishedEvent;

const PlayerWrapper = styled.div<{ isReady: boolean }>`
  position: relative;
  background: ${({ isReady }) =>
    isReady ? xdlColorControlFillQuaternary : xdlColorBackgroundTertiary};
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const playerWrapperContentStyles = css`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoPlayer = styled(ReactPlayer)`
  ${playerWrapperContentStyles}
`;

const VideoError = styled(StatusBanner)`
  ${playerWrapperContentStyles}
`;

export const CompanyVideoModule: React.FC<CompanyVideo> = ({
  jobId,
  videoUrl,
}) => {
  const [hasError, setError] = useState(false);
  const [isReady, setReady] = useState(false);
  const { track } = useTracking<VideoTrackingEvent>();
  const trackNwtEvent = useJobsNwtTracking();
  const { hasConsent } = useExternalContentConsent();

  if (!videoUrl) return null;

  const onReady = (): void => {
    setReady(true);
    track({
      event: 'EventVideoLoaded',
      EventVideoLoaded: 1,
      PropJobsId: jobId,
    });
  };
  const onError = (): void => setError(true);
  const onStart = (): void => {
    track({
      event: 'EventVideoStarted',
      EventVideoStarted: 1,
      PropActionOrigin: `video_autoplay_no`,
      PropJobsId: jobId,
    });

    trackNwtEvent({
      eventSchema: 'basic',
      page: 'jobs/detail',
      event: 'media_started',
      element: 'job_description_video',
      itemUrn: `urn:x-xing:jobs:posting:${jobId}`,
    });
  };
  const onEnded = (): void =>
    track({
      event: 'EventVideoFinished',
      EventVideoFinished: 1,
      PropJobsId: jobId,
    });

  return (
    <div>
      <ModuleTitle
        titleKey="JOBS_JOB_DETAILS_EMPLOYER_SHOWCASE_VIDEO"
        size="large"
      />
      {hasConsent ? (
        <PlayerWrapper isReady={isReady}>
          {hasError ? (
            <VideoError display="inline" variant="error">
              <FormattedMessage id="JOBS_JOB_DETAILS_DEFAULT_ERROR" />
            </VideoError>
          ) : (
            <VideoPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              controls
              onReady={onReady}
              onError={onError}
              onStart={onStart}
              onEnded={onEnded}
            />
          )}
        </PlayerWrapper>
      ) : (
        <VideoConsentPlaceholder url={videoUrl} />
      )}
    </div>
  );
};

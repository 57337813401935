import { useLinkTracking } from '../use-link-tracking';

import {
  SectionWrapper,
  StyledContent,
  StyledHeadline,
} from './section.styles';

type SectionProps = {
  jobId: string;
  title?: string;
  content: string;
  titleColor?: string;
};

export const Section: React.FC<SectionProps> = ({
  jobId,
  title,
  content,
  titleColor,
}) => {
  const linkTrackingContainerRef = useLinkTracking(jobId);

  return (
    <SectionWrapper>
      {title ? (
        // @ts-expect-error FIXME: SC6
        <StyledHeadline
          $customColor={titleColor}
          dangerouslySetInnerHTML={{ __html: title }}
        ></StyledHeadline>
      ) : null}
      <StyledContent
        dangerouslySetInnerHTML={{ __html: content }}
        ref={linkTrackingContainerRef}
      />
    </SectionWrapper>
  );
};

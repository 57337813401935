import { useNavigate } from '@reach/router';
import { useCallback, useMemo } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { useIsExternalApplicationFlowEnabled } from '../../../../hooks';

import { useGetApplyUrlWithTrackingParams } from './use-get-apply-url-with-tracking-params';
import { useTrackApplyIntention } from './use-track-apply-intention';

type Props = {
  slug?: string;
  applyUrl?: string;
  isPrioritizedJob?: boolean;
  jobId: string;
};

type UrlApplyProps = {
  onClick: () => void;
};

export const useApplyButtonUrlApplyProps = ({
  slug,
  applyUrl,
  jobId,
  isPrioritizedJob = false,
}: Props): UrlApplyProps => {
  const navigate = useNavigate();
  const trackApplyIntention = useTrackApplyIntention({
    applicationType: 'UrlApplication',
    jobId,
  });
  const { isLoggedOut } = useLoginState();
  const {
    isExternalApplicationFlowEnabled,
    externalApplicationFlowTrackingProps,
  } = useIsExternalApplicationFlowEnabled(isPrioritizedJob);

  const applyUrlWithTrackingParams = useGetApplyUrlWithTrackingParams(applyUrl);

  const showExternalApplyFlow = useCallback(() => {
    navigate(`/jobs/external-apply/${slug}`);
  }, [slug]);

  const onClick = useCallback(() => {
    trackApplyIntention(
      isLoggedOut ? externalApplicationFlowTrackingProps : undefined
    );
    if (isLoggedOut && isExternalApplicationFlowEnabled) {
      showExternalApplyFlow();
    } else {
      window.open(applyUrlWithTrackingParams, '_blank', 'noopener');
    }
  }, [
    applyUrlWithTrackingParams,
    externalApplicationFlowTrackingProps,
    isExternalApplicationFlowEnabled,
    isLoggedOut,
    showExternalApplyFlow,
    trackApplyIntention,
  ]);

  const urlProps = useMemo(
    () => ({
      to: undefined,
      onClick,
    }),
    [onClick]
  );

  return urlProps;
};

import type React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { CompassData } from '@xing-com/crate-jobs-helpers';
import { useBreakpoints } from '@xing-com/crate-jobs-hooks';
import { IconInfo } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';

import { CultureLegend } from '../culture-legend';

import { CultureCompass } from './culture-compass';
import {
  CompassLegendButton,
  CompassWrapper,
  Legend,
  StyledContentBanner,
  StyledPopOver,
} from './culture-compass-wrapper.styles';

type Props = {
  compass: CompassData;
  companyName: string;
};

const cultureCompassLegendValues = {
  top: (chunks: React.ReactNode) => (
    <>
      <strong>{chunks}</strong>
      <br />
    </>
  ),
};

const TooltipButton: React.FC<{ messageId: string; helperText: string }> = ({
  messageId,
  helperText,
}) => {
  const [showContentBanner, setShowContentBanner] = useState(false);
  const { isMediaSmallOrTiny } = useBreakpoints();
  const popOver = usePopOver();

  const handleOnClick = (): void => {
    if (isMediaSmallOrTiny) {
      setShowContentBanner((prev) => !prev);
    } else {
      popOver.togglePopOver();
    }
  };

  return (
    <>
      <CompassLegendButton
        innerRef={isMediaSmallOrTiny ? popOver.triggerRef : null}
        onClick={handleOnClick}
      >
        <Legend>
          <FormattedMessage
            id={messageId}
            values={cultureCompassLegendValues}
          />
        </Legend>
        <IconInfo width={18} height={18} />
      </CompassLegendButton>

      <StyledContentBanner
        display="fixed"
        show={showContentBanner}
        showCloseButton
        handleOnClose={() => setShowContentBanner(false)}
      >
        <FormattedMessage id={helperText} />
      </StyledContentBanner>

      <StyledPopOver
        isInFlow
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <FormattedMessage id={helperText} />
      </StyledPopOver>
    </>
  );
};

export const CultureCompassWrapper: React.FC<Props> = ({
  compass,
  companyName,
}) => {
  return (
    <CompassWrapper>
      <CultureLegend companyName={companyName} />
      <CultureCompass
        industryAngle={compass?.industry}
        profileAngle={compass?.profile}
        leftLegend={
          <TooltipButton
            messageId="JOBS_JOB_DETAILS_COMPANY_CULTURE_COMPASS_LEGEND_LEFT"
            helperText="CAREER_GUIDANCE_DETAILS_PAGE_TRADITIONAL_1"
          />
        }
        rightLegend={
          <TooltipButton
            messageId="JOBS_JOB_DETAILS_COMPANY_CULTURE_COMPASS_LEGEND_RIGHT"
            helperText="CAREER_GUIDANCE_DETAILS_PAGE_MODERN_1"
          />
        }
      />
    </CompassWrapper>
  );
};

import { useMutation } from '@apollo/client';
import type React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { Checkbox } from '@xing-com/checkbox';
import { JobReportReason } from '@xing-com/crate-common-graphql-types';
import type { JobReportableField } from '@xing-com/crate-common-graphql-types';
import { CreateJobReportDocument } from '@xing-com/crate-jobs-graphql';
import { Label } from '@xing-com/label';
import { PopUpWindow } from '@xing-com/pop-up-window';
import { RadioButton } from '@xing-com/radio-button';
import { RadioButtonGroup } from '@xing-com/radio-button-group';
import { FormField } from '@xing-com/text-field';
import { spaceXXL, spaceL, spaceM, spaceS, spaceXL } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

import { reportJobOptions, reportJobReasons } from './constants';

const OverflowWrapper = styled.div`
  overflow-y: auto;
`;

const OptionLabel = styled(Label)`
  display: flex;
  gap: ${spaceM};
`;

const TextAreaLabel = styled(Label)`
  margin-top: ${spaceL};
  margin-bottom: ${spaceS};
`;

const RadioButtonContainer = styled(RadioButtonGroup)`
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.li`
  display: flex;
  flex-direction: column;
  margin-left: ${spaceXXL};
  margin-top: ${spaceM};
  gap: ${spaceXL};
`;

const ActionsContainer = styled.div`
  margin-top: ${spaceXL};
  display: flex;
  justify-content: end;
  gap: ${spaceXXL};
`;

const SubmitButton = styled(Button)`
  width: 180px;
`;

const isRadioButtonOption = (
  option: JobReportReason | JobReportableField
): option is JobReportReason => {
  return ['ILLEGAL_CONTENT', 'NO_JOB_AD', 'WRONG_INFORMATION'].includes(option);
};

const Option: React.FC<{
  on: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  option: JobReportReason | JobReportableField;
}> = ({ on, onChange, option }) => {
  return (
    <OptionLabel>
      {isRadioButtonOption(option) ? (
        <RadioButton id={option} onChange={onChange} on={on} />
      ) : (
        // @ts-expect-error FIXME: SC6
        <Checkbox id={option} onChange={onChange} checked={on} />
      )}
      <FormattedMessage id={`JOBS_JOB_DETAILS_REPORT_JOB_${option}`} />
    </OptionLabel>
  );
};

type Props = {
  isShown: boolean;
  jobId: string;
  toggle: () => void;
  reportJobAd: (jobId: string) => void;
};

export const ReportJobModal: React.FC<Props> = ({
  isShown,
  toggle,
  jobId,
  reportJobAd,
}) => {
  const [reportJobOption, setReportJobOption] = useState<JobReportReason>(
    JobReportReason.NoJobAd
  );

  const [wrongFields, setWrongFields] = useState<JobReportableField[]>([]);
  const [textAreaInput, setTextAreaInput] = useState<string>('');
  const { formatMessage } = useIntl();

  const updateWrongFields = (wrongField?: JobReportableField): void => {
    if (!wrongField) return setWrongFields([]);

    if (!wrongFields.includes(wrongField)) {
      setWrongFields([...wrongFields, wrongField]);
    }

    if (wrongFields.includes(wrongField)) {
      setWrongFields(wrongFields.filter((field) => field !== wrongField));
    }
  };

  const [createJobReportMutation] = useMutation(CreateJobReportDocument, {
    variables: {
      jobId,
      reason: reportJobOption,
      wrongFields,
      comment: textAreaInput,
    },
    onCompleted: (data) => {
      if (!data.jobCreateReport?.error) {
        reportJobAd(jobId);
      }
    },
  });

  const handleOptionChange = (
    option: JobReportReason | JobReportableField
  ): void => {
    if (isRadioButtonOption(option)) {
      setReportJobOption(option);
      updateWrongFields();
    } else {
      setReportJobOption(JobReportReason.WrongInformation);
      updateWrongFields(option);
    }
  };

  const handleOnSubmit = (): void => {
    createJobReportMutation();
    toggle();
  };

  return (
    <PopUpWindow isShown={isShown} hide={toggle} onDimmerClick={toggle}>
      <OverflowWrapper>
        <Headline>
          <FormattedMessage id="JOBS_JOB_DETAILS_REPORT_JOB_TITLE" />
        </Headline>
        <BodyCopy>
          <FormattedMessage id="JOBS_JOB_DETAILS_REPORT_JOB_DESCRIPTION" />
        </BodyCopy>
        <RadioButtonContainer>
          {reportJobOptions.map((key) => (
            <Option
              option={key}
              key={key}
              on={reportJobOption === key}
              onChange={() => handleOptionChange(key)}
            />
          ))}
        </RadioButtonContainer>
        <CheckboxContainer>
          {reportJobReasons.map((key) => (
            <Option
              option={key}
              key={key}
              on={wrongFields.includes(key)}
              onChange={() => handleOptionChange(key)}
            />
          ))}
        </CheckboxContainer>

        <TextAreaLabel>
          <FormattedMessage id="JOBS_JOB_DETAILS_REPORT_JOB_ADD_COMMENT" />
        </TextAreaLabel>
        <FormField
          multiline
          minRows={5}
          size="large"
          placeholder={formatMessage({
            id: 'JOBS_JOB_DETAILS_REPORT_JOB_COMMENT_PLACEHOLDER',
          })}
          value={textAreaInput}
          // @ts-expect-error FIXME: SC6
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
            setTextAreaInput(e.target.value)
          }
        />
        <ActionsContainer>
          {/* @ts-expect-error FIXME: SC6 */}
          <TextButton variant="tertiary" onClick={toggle}>
            <FormattedMessage id="JOBS_JOB_DETAILS_CANCEL" />
          </TextButton>
          <SubmitButton variant="primary" onClick={handleOnSubmit}>
            <FormattedMessage id="JOBS_JOB_DETAILS_REPORT_JOB_BUTTON" />
          </SubmitButton>
        </ActionsContainer>
      </OverflowWrapper>
    </PopUpWindow>
  );
};

import { useNavigate } from '@reach/router';
import type React from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { JDP_BACK_PARAMS, JDP_JOB_SLUGS } from '@xing-com/crate-jobs-constants';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useBreakpoints, useNavigationState } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { IconArrowLeft, IconArrowRight } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL } from '@xing-com/tokens';

const PREVIOUS_SECTION_BUTTON: Record<string, { copy: string; route: string }> =
  {
    jb_12: { copy: 'JOBS_MYJOBS_SAVED_SECTION_TITLE', route: ROUTES.myJobs },
    jb_18: { copy: 'JOBS_SEARCH_JOBS_LINK', route: ROUTES.search },
    jb_36: { copy: 'JOBS_SEARCH_JOBS_LINK', route: ROUTES.search },
    jb_37: { copy: 'JOBS_SEARCH_JOBS_LINK', route: ROUTES.search },
    jb_55: { copy: 'JOBS_SEARCH_JOBS_LINK', route: ROUTES.searchAi },
  };
const DEFAULT_PREVIOUS_SECTION_BUTTON = {
  copy: 'JOBS_FIND_JOBS_LINK',
  route: '/',
};

type NavigationContainerProps = {
  $showPreviousJob?: boolean;
  $noMargin?: boolean;
};

const NavigationContainer = styled.div<NavigationContainerProps>`
  display: flex;

  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : spaceL)};
  justify-content: ${({ $showPreviousJob }) =>
    $showPreviousJob ? 'space-between' : 'end'};

  @media ${mediaConfined} {
    margin-top: ${({ $noMargin }) => ($noMargin ? 0 : 28)}px;
    margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : 20)}px;
    justify-content: space-between;
  }

  @media print {
    display: none;
  }
`;

type Props = React.PropsWithChildren<{
  job: JobDetails;
  similarJobSlugs: string[];
  isHeaderSticky: boolean;
  noMargin?: boolean;
}>;

export const Navigation: React.FC<Props> = ({
  job,
  isHeaderSticky,
  similarJobSlugs,
  noMargin = false,
}) => {
  const { isLoggedIn } = useLoginState();
  const navigate = useNavigate();
  const state = useNavigationState();
  const { isMediaSmallOrTiny } = useBreakpoints();
  const { getSearchParameter } = useSearchParameter();
  const trackNwt = useJobsNwtTracking();
  const navIndex = state?.[JDP_JOB_SLUGS]?.index || 0;
  const ijtParam = getSearchParameter('ijt');
  const previousSectionButton =
    PREVIOUS_SECTION_BUTTON[ijtParam ?? ''] ?? DEFAULT_PREVIOUS_SECTION_BUTTON;

  const jobSlugs = Array.isArray(state?.[JDP_JOB_SLUGS]?.list)
    ? state[JDP_JOB_SLUGS].list
    : similarJobSlugs;

  const handleNavigation = useCallback(
    (to?: 'nextJob' | 'previousJob') => () => {
      if (!to) {
        const path = previousSectionButton.route;
        const urlParams = state?.[JDP_BACK_PARAMS];
        const url = `${path}${urlParams ? urlParams : ''}`;
        trackNwt({
          eventSchema: 'basic',
          event: 'clicked',
          element: 'jobs_previous_section_link',
          page: `jobs/detail${job.redirectsToThirdPartyUrl ? '_external' : ''}`,
        });

        if (path === '/' && !isLoggedIn) {
          window.location.href = url;
          return;
        }

        navigate(url);
        return;
      }

      const index = navIndex + (to === 'nextJob' ? 1 : -1);
      const path = generatePath(ROUTES.jobDetails, {
        slug: jobSlugs[index],
      });
      const url = `${path}${ijtParam ? `?ijt=${ijtParam}` : ''}`;

      trackNwt({
        eventSchema: 'basic',
        event: 'clicked',
        element:
          to === 'nextJob' ? 'jobs_next_job_link' : 'jobs_previous_job_link',
        page: `jobs/detail${job.redirectsToThirdPartyUrl ? '_external' : ''}`,
      });

      navigate(url, {
        state: {
          ...state,
          [JDP_JOB_SLUGS]: {
            list: jobSlugs,
            type: state?.[JDP_JOB_SLUGS]?.type ?? 'similarJobs',
            index,
          },
        },
      });
    },
    [
      ijtParam,
      isLoggedIn,
      job.redirectsToThirdPartyUrl,
      jobSlugs,
      navIndex,
      navigate,
      previousSectionButton.route,
      state,
      trackNwt,
    ]
  );

  if (!(isHeaderSticky && !isMediaSmallOrTiny) && isHeaderSticky) {
    return null;
  }

  const nextJobButtonCopy =
    !state?.[JDP_JOB_SLUGS]?.list || state[JDP_JOB_SLUGS].type === 'similarJobs'
      ? 'JOBS_JOB_DETAILS_NEXT_SIMILAR_JOB'
      : 'JOBS_JOB_DETAILS_NEXT_JOB';

  const showNextJobButton = !!jobSlugs[navIndex + 1];
  const showPreviousJobButton = !!jobSlugs[navIndex - 1] && isMediaSmallOrTiny;
  const showPreviousSectionButton = !isMediaSmallOrTiny;

  return (
    <NavigationContainer
      $showPreviousJob={showPreviousJobButton}
      $noMargin={noMargin}
    >
      {showPreviousSectionButton ? (
        <TextButton
          icon={IconArrowLeft}
          data-testid="previous-section-button"
          onClick={handleNavigation()}
        >
          <FormattedMessage id={previousSectionButton.copy} />
        </TextButton>
      ) : null}
      {showPreviousJobButton ? (
        <TextButton
          icon={IconArrowLeft}
          data-testid="previous-job-button"
          onClick={handleNavigation('previousJob')}
        >
          <FormattedMessage id="JOBS_JOB_DETAILS_PREVIOUS_JOB" />
        </TextButton>
      ) : null}

      {showNextJobButton ? (
        <TextButton
          icon={IconArrowRight}
          iconAfter
          data-testid="next-job-button"
          onClick={handleNavigation('nextJob')}
        >
          <FormattedMessage id={nextJobButtonCopy} />
        </TextButton>
      ) : null}
    </NavigationContainer>
  );
};

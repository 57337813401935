import type React from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { useIsJobDetailHeaderInView } from '@xing-com/crate-jobs-hooks';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { useExperiment } from '@xing-com/hub';

import { ShareJobProvider } from '../../hooks';

import { MainActions, TopActions } from './actions';
import { StyledConfirmation, StyledHeader, TopContent } from './header.styles';
import { EarnUpToHighlight, Highlights } from './highlights';
import { JobFacts } from './job-facts';
import { JobIntro } from './job-intro';
import { Navigation } from './navigation';
import { PublishedDate } from './published-date';
import { StickyHeader } from './sticky-header';
import { Title } from './title';

type Props = {
  job: JobDetails;
  similarJobSlugs: string[];
};

export const Header: React.FC<Props> = ({ job, similarJobSlugs }) => {
  const {
    ref: stickyHeaderRef,
    refElement: stickyHeaderRefElement,
    isInView,
  } = useIsJobDetailHeaderInView(false);
  const { isLoggedIn } = useLoginState();
  const jobRefreshExperimentVariant = useExperiment('ABACUS-483');
  const showRefreshedAt = jobRefreshExperimentVariant === 'B';

  const {
    activatedAt,
    refreshedAt,
    application,
    bookmarkState,
    company,
    facts,
    globalId,
    highlights,
    industry,
    salary,
    title,
    url,
  } = job;

  const withStickyHeader = !job.redirectsToThirdPartyUrl && !isInView;
  const stickyHeaderProps = {
    job,
    show: withStickyHeader,
    headerRef: stickyHeaderRefElement,
  };

  return (
    <ShareJobProvider globalId={globalId} url={url}>
      <StyledHeader ref={stickyHeaderRef} data-testid="job-details-header">
        <Navigation
          job={job}
          isHeaderSticky={withStickyHeader}
          similarJobSlugs={similarJobSlugs}
        />
        <TopContent>
          <PublishedDate date={showRefreshedAt ? refreshedAt : activatedAt} />
          <TopActions
            applicationStatus={application.status}
            bookmarkState={bookmarkState}
            isHeaderSticky={withStickyHeader}
            job={job}
          />
        </TopContent>
        <EarnUpToHighlight label={highlights.earnUpTo} />
        <Title title={title} />
        <JobIntro company={company} industry={industry?.name} />
        <JobFacts
          jobFacts={facts}
          salary={
            !isLoggedIn || !highlights.isMatchingSalary ? salary : undefined
          }
        />
        <Highlights highlights={highlights} salary={salary} />
        <NoSSR>
          {/*
            The confirmation banner looks into local storage to know if a
            LO user has applied to the current job. The NoSSR component is used
            to ensure that it is rendered only on client side.
          */}
          <StyledConfirmation application={application} />
        </NoSSR>
        <MainActions
          application={application}
          job={job}
          bookmarkState={bookmarkState}
        />
      </StyledHeader>
      <StickyHeader {...stickyHeaderProps}>
        <Navigation
          job={job}
          isHeaderSticky={withStickyHeader}
          similarJobSlugs={similarJobSlugs}
          noMargin
        />
      </StickyHeader>
    </ShareJobProvider>
  );
};

import VimeoPlayer from 'react-player/vimeo';
import YoutubePlayer from 'react-player/youtube';
import styled, { css } from 'styled-components';

import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXL } from '@xing-com/tokens';

type Props = {
  type: 'vimeo' | 'youtube';
  url: string;
  jobId: string;
};

const VideoWrapper = styled.section`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  width: 100%;
  margin: ${spaceXL} 0;

  @media ${mediaConfined} {
    margin: calc(${spaceXL} * 2) 0;
  }
`;

const videoStyles = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
`;

const StyledVimeoPlayer = styled(VimeoPlayer)`
  ${videoStyles}
`;

const StyledYoutubePlayer = styled(YoutubePlayer)`
  ${videoStyles}
`;

export const Video: React.FC<Props> = ({ type, url, jobId }) => {
  const trackNwtEvent = useJobsNwtTracking();

  const videoProps = {
    url,
    controls: true,
    width: '100%',
    height: '100%',
    'data-testid': `${type}-video`,
    title: `${type}-player`,
    onPlay: () => {
      trackNwtEvent({
        eventSchema: 'basic',
        page: 'jobs/detail',
        event: 'media_started',
        element: 'job_description_video',
        itemUrn: `urn:x-xing:jobs:posting:${jobId}`,
      });
    },
  };

  return (
    <VideoWrapper>
      {type === 'vimeo' ? (
        <StyledVimeoPlayer {...videoProps} />
      ) : type === 'youtube' ? (
        <StyledYoutubePlayer {...videoProps} />
      ) : null}
    </VideoWrapper>
  );
};

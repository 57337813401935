import type React from 'react';
import styled from 'styled-components';

import type { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import type { JobDetails, ApplicationData } from '@xing-com/crate-jobs-helpers';
import { useBreakpoints } from '@xing-com/crate-jobs-hooks';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceS } from '@xing-com/tokens';

import { ApplyButton } from './apply-button';
import { BookmarkButton } from './bookmark-button';
import { useMainActions } from './use-main-actions';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaceS};
  width: auto;

  @media ${mediaConfined} {
    padding: 0;
    flex-direction: row;
  }

  @media print {
    display: none;
  }
`;

type Props = {
  application: ApplicationData;
  bookmarkState?: JobBookmarkState;
  job: JobDetails;
};

export const MainActions: React.FC<Props> = ({
  application,
  job,
  bookmarkState,
}) => {
  const { showApplyButton, showBookmarkButton } = useMainActions({
    application,
    job,
  });
  const { isMediaSmallOrTiny } = useBreakpoints();

  return (
    <ActionsContainer>
      {showApplyButton ? (
        <ApplyButton application={application} variant="primary" />
      ) : null}
      {showBookmarkButton ? (
        <BookmarkButton
          job={job}
          bookmarkState={bookmarkState}
          iconOnly={showApplyButton && isMediaSmallOrTiny}
        />
      ) : null}
    </ActionsContainer>
  );
};

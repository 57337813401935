import type React from 'react';
import styled from 'styled-components';

import type { EmployerData } from '@xing-com/crate-jobs-helpers';
import { Divider } from '@xing-com/divider';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { space3XL, space4XL, spaceXL } from '@xing-com/tokens';

import { ModuleTitle } from '../../module-title';

import { CultureCompassWrapper } from './culture-compass-wrapper';
import { CultureInfo } from './culture-info';
import { CultureLink } from './culture-link';

const CultureTitle: React.FC<{ className?: string }> = ({ className }) => (
  <ModuleTitle
    titleKey="JOBS_JOB_DETAILS_COMPANY_CULTURE_TITLE"
    size="large"
    className={className}
  />
);

export const StyledCultureTitle = styled(CultureTitle)`
  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

export const CultureTitleMobile = styled(CultureTitle)`
  @media ${mediaConfined} {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  margin-top: ${space3XL};
  flex-direction: column;
  gap: ${spaceXL};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${space4XL};
  }
`;

const CorporateCulture = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  @media ${mediaSmallOrTiny} {
    display: none;
  }
`;

type Props = {
  culture: EmployerData['culture'];
  ratingCount?: number;
  jobId: string;
};

export const Culture: React.FC<Props> = ({ culture, ratingCount, jobId }) => {
  const { compass, link, companyName } = culture ?? undefined;

  if (!compass) return null;

  return (
    <>
      <StyledDivider />
      <Container>
        <CultureTitleMobile />
        <CultureCompassWrapper compass={compass} companyName={companyName} />
        <CorporateCulture>
          <StyledCultureTitle />
          <CultureInfo
            companyName={companyName}
            compass={compass}
            ratingCount={ratingCount}
          />
          {link ? <CultureLink link={link} jobId={jobId} /> : null}
        </CorporateCulture>
      </Container>
    </>
  );
};

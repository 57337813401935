import styled from 'styled-components';

import type { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import { JobUserXingApplicationStatus } from '@xing-com/crate-common-graphql-types';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { JobDetails } from '@xing-com/crate-jobs-helpers';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceS, spaceXS } from '@xing-com/tokens';

import { BookmarkButton } from './bookmark-button';
import { MoreMenuButton } from './more-menu-button';

const ActionsContainer = styled.div`
  display: flex;
  gap: ${spaceXS};

  @media ${mediaConfined} {
    gap: ${spaceS};
  }

  @media print {
    display: none;
  }
`;

type Props = {
  applicationStatus?: JobUserXingApplicationStatus;
  bookmarkState?: JobBookmarkState;
  isHeaderSticky: boolean;
  job: JobDetails;
};

export const TopActions: React.FC<Props> = ({
  applicationStatus,
  bookmarkState,
  isHeaderSticky,
  job,
}) => {
  const { isLoggedIn } = useLoginState();

  const hasApplied =
    applicationStatus === JobUserXingApplicationStatus.Success ||
    applicationStatus === JobUserXingApplicationStatus.Pending;
  const showMoreMenuButton = (hasApplied || !isHeaderSticky) && isLoggedIn;

  return (
    <ActionsContainer>
      {showMoreMenuButton ? <MoreMenuButton job={job} /> : null}
      {hasApplied ? (
        <BookmarkButton
          bookmarkState={bookmarkState}
          job={job}
          size="small"
          sizeConfined="medium"
          iconOnly
        />
      ) : null}
    </ActionsContainer>
  );
};

import styled, { css } from 'styled-components';

import { Observer } from '@xing-com/crate-communication-in-view';
import { TopBar as TobBarComponent } from '@xing-com/crate-layout-top-bar';
import { GridContainer, GridItem } from '@xing-com/grid';
import {
  mediaConfinedNavlessOnly,
  mediaNavExtended,
  mediaSmallOrTiny,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  space3XL,
  space6XL,
  spaceXXL,
  scale170,
  scale180,
  scale290,
} from '@xing-com/tokens';

export const StyledObserver = styled(Observer)`
  width: 100%;
`;

const DEFAULT_SCROLL_MOBILE_HEIGHT = '800px'; // Min height to let the user scroll the full JDP header, description headline and a small part of the description on small devices
const TOP_BAR_HEIGHT_MOBILE = scale170;
const BOTTOM_BAR_HEIGHT_MOBILE = scale180;

export const Container = styled.div<{
  $isScrollFixed?: boolean;
  $isLiUser?: boolean;
}>`
  max-width: 944px;
  margin: 0 auto;

  ${({ $isScrollFixed, $isLiUser }) =>
    $isScrollFixed &&
    css`
      overflow-y: clip;
      height: ${DEFAULT_SCROLL_MOBILE_HEIGHT};
      position: relative;

      @media ${mediaSmallOrTiny} {
        // Not LI --> top bar + bottom menu + footer --> external job banner is position absolute
        // LI --> top bar + bottom menu, no footer --> external job banner is position fixed
        min-height: ${$isLiUser
          ? '100vh'
          : `calc(100vh - ${TOP_BAR_HEIGHT_MOBILE} - ${BOTTOM_BAR_HEIGHT_MOBILE})`};
      }

      @media ${mediaConfinedNavlessOnly} {
        // bottom menu + mobile nav top bar + footer
        min-height: calc(
          100vh - ${TOP_BAR_HEIGHT_MOBILE} - ${BOTTOM_BAR_HEIGHT_MOBILE}
        );
      }

      @media ${mediaWideNavless} {
        // side thin menu + footer: set same min-height than main layout wrapper (see layout-main.styles.ts) or a minimum of side menu height
        min-height: max(calc(100vh - ${scale290}), 950px);
      }

      @media ${mediaNavExtended} {
        // side menu + footer: set same min-height than main layout wrapper (see layout-main.styles.ts) or a minimum of side menu height
        min-height: max(calc(100vh - ${scale290}), 850px);
      }
    `};
`;

export const TopBar = styled(TobBarComponent)`
  @media print {
    display: none;
  }
`;

export const JobDetailsGridContainer = styled(GridContainer).attrs({
  variant: 'FullSize',
})`
  row-gap: ${space3XL};
  padding: 0;
  margin-bottom: ${space6XL};

  @media print {
    margin-bottom: 0;
  }
`;

export const SimilarJobsSidebarGridItem = styled(GridItem).attrs({
  spanLarge: 4,
})`
  display: none;
  margin-left: ${spaceXXL};

  @media ${mediaWideNavless} {
    display: block;
  }
`;

import type { PropsWithChildren } from 'react';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconArrowDown, IconArrowUp } from '@xing-com/icons';

import {
  ExpandableContent,
  ShowMoreContainer,
  ToggleButton,
} from './show-more.styles';

type Props = {
  previewHeight: number;
  expandLabelKey: string;
  collapseLabelKey: string;
  size?: 'small' | 'medium';
  centered?: boolean;
  initiallyExpanded?: boolean;
  hideOnDesktop?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
};

export const ShowMore: React.FC<PropsWithChildren<Props>> = ({
  children,
  previewHeight,
  size = 'medium',
  centered = false,
  expandLabelKey,
  collapseLabelKey,
  initiallyExpanded = false,
  hideOnDesktop,
  onExpand,
  onCollapse,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const showPreview = height > previewHeight;

  useEffect(() => {
    if (contentRef.current) setHeight(contentRef.current.scrollHeight);
  }, []);

  const onClick = (): void => {
    // Run optional callbacks
    isExpanded ? onCollapse?.() : onExpand?.();

    // Toggle expanded state
    setIsExpanded(!isExpanded);
  };

  return (
    <ShowMoreContainer>
      <ExpandableContent
        $isExpanded={isExpanded}
        $previewHeight={previewHeight}
        $hideOnDesktop={hideOnDesktop}
        ref={contentRef}
        data-testid="expandable-content"
      >
        {children}
      </ExpandableContent>
      {showPreview ? (
        <ToggleButton
          icon={isExpanded ? IconArrowUp : IconArrowDown}
          iconAfter
          size={size}
          onClick={onClick}
          $hideOnDesktop={hideOnDesktop}
          $centered={centered}
          $size={size}
        >
          <FormattedMessage
            id={isExpanded ? collapseLabelKey : expandLabelKey}
          />
        </ToggleButton>
      ) : null}
    </ShowMoreContainer>
  );
};

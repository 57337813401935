import type { JobDetails } from '@xing-com/crate-jobs-helpers';

import type { JDPModule, ModuleVisibility } from '../../types';

type ModulesVisibilityReturn = {
  visibleModules: ModuleVisibility;
  modulesList: string;
};

const jobHasHeaderImage = (jobDetails: JobDetails): boolean => {
  // Check if the job has a banner
  if (jobDetails.banner) return true;

  // Check if the job description is of "TemplateData" type and contains a
  // header image URL
  if (
    jobDetails.description.__typename === 'TemplateData' &&
    !!jobDetails.description.headerImage
  )
    return true;

  // Check if the job description was compiled by XJM and contains a section
  // with the "header-image" class. False otherwise (which covers all the other
  // cases)
  return !!(
    jobDetails.description.__typename === 'HtmlDescription' &&
    jobDetails.description.content?.includes('<section class="header-image">')
  );
};

export const getModulesVisibility = (
  jobDetails: JobDetails,
  isLoggedIn: boolean
): ModulesVisibilityReturn => {
  const {
    company,
    employerData,
    paid,
    redirectsToThirdPartyUrl,
    salary,
    summaryData,
    videoUrl,
  } = jobDetails;

  const visibleModules: ModuleVisibility = {
    Header: true,
    Summary: !!summaryData && !redirectsToThirdPartyUrl,
    MatchingBanner:
      paid &&
      !isLoggedIn &&
      !jobHasHeaderImage(jobDetails) &&
      !redirectsToThirdPartyUrl,
    Description: true,
    Salary: !!salary && !redirectsToThirdPartyUrl,
    Company: !!company?.id && !redirectsToThirdPartyUrl,
    Culture: !!employerData && !redirectsToThirdPartyUrl,
    Contacts: !!company?.id && isLoggedIn && !redirectsToThirdPartyUrl,
    SearchAlerts: !redirectsToThirdPartyUrl,
    SimilarJobs: !redirectsToThirdPartyUrl,
    Video: !!videoUrl && !redirectsToThirdPartyUrl,
  };

  const modulesList = Object.keys(visibleModules)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    .filter((key) => visibleModules[key as JDPModule])
    .join(',');

  return {
    visibleModules,
    modulesList,
  };
};

import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import {
  type InteractionTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { BOTTOM_BAR_HEIGHT } from '@xing-com/crate-jobs-constants';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { IconExternalLink } from '@xing-com/icons';
import { mediaConfined, zIndexAboveContent } from '@xing-com/layout-tokens';
import {
  space3XL,
  spaceL,
  spaceS,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

type Props = { url: string; jobId: string };

const Container = styled.div<{ $isLiUser: boolean }>`
  text-align: center;
  padding: ${spaceXXL} ${spaceL};
  position: ${({ $isLiUser }) => ($isLiUser ? 'fixed' : 'absolute')};
  bottom: ${({ $isLiUser }) => ($isLiUser ? `${BOTTOM_BAR_HEIGHT}px` : 0)};
  left: 0;
  width: 100%;
  background-color: ${xdlColorBackground};
  z-index: ${zIndexAboveContent};
  box-shadow: rgba(33, 42, 54, 0.13) 0px -10px 10px -10px; // custom top shadow only

  @media ${mediaConfined} {
    padding: ${space3XL} 0;
    position: absolute;
    bottom: 0;
  }
`;

const Title = styled(Headline)`
  margin-bottom: ${spaceS};
`;

const Body = styled(BodyCopy)`
  margin-bottom: ${spaceXL};
`;

export const ExternalJobBanner: React.FC<Props> = ({ url, jobId }) => {
  const { isLoggedIn } = useLoginState();
  const trackNwt = useJobsNwtTracking();
  const { track: trackAdobe } = useTracking<
    InteractionTrackingEvent<{
      PropTrackAction: string;
      PropJobsId: string;
      PropJobsApplyId: string;
    }>
  >();

  const onCtaClick = useCallback(() => {
    trackNwt({
      eventSchema: 'basic',
      event: 'visited',
      page: 'jobs/detail_external',
      element: 'external_job_posting',
      itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
    });

    trackAdobe({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_click_external_job_posting',
      PropJobsId: jobId,
      PropJobsApplyId: crypto.randomUUID(),
    });
  }, [trackNwt, jobId, trackAdobe]);

  return (
    <Container $isLiUser={isLoggedIn}>
      <Title size="xxlarge">
        <FormattedMessage id="JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_TITLE" />
      </Title>
      <Body>
        <FormattedMessage id="JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_BODY" />
      </Body>
      <Button
        variant="primary"
        icon={IconExternalLink}
        size="large"
        href={url}
        // @ts-expect-error FIXME: SC6
        target="_blank"
        rel="noopener"
        onClick={onCtaClick}
      >
        <FormattedMessage id="JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_CTA" />
      </Button>
    </Container>
  );
};

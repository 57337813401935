import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import type { CompanyMappedBenefitType } from '@xing-com/crate-common-graphql-types';
import { Tag } from '@xing-com/tag';
import { space3XL, spaceS, spaceXXL } from '@xing-com/tokens';

import { ModuleTitle } from '../module-title';
import { ShowMore } from '../show-more';

const COMPANY_BENEFIT = 'COMPANY_BENEFIT';

const Content = styled.div`
  margin-top: ${spaceXXL};

  &:not(:last-child) {
    margin-bottom: ${space3XL};
  }
`;

const BenefitsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceS};
`;

type Props = {
  benefits: CompanyMappedBenefitType[];
};

export const Benefits: React.FC<Props> = ({ benefits }) =>
  benefits?.length > 0 ? (
    <Content>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_KUNUNU_BENEFITS" size="large" />
      <ShowMore
        previewHeight={40}
        expandLabelKey="JOBS_JOB_DETAILS_KUNUNU_BENEFITS_SHOW_ALL"
        collapseLabelKey="JOBS_JOB_DETAILS_KUNUNU_BENEFITS_SHOW_LESS"
        size="small"
      >
        <BenefitsList>
          {benefits?.map((benefit) => (
            <Tag key={benefit}>
              <FormattedMessage id={`${COMPANY_BENEFIT}_${benefit}`} />
            </Tag>
          ))}
        </BenefitsList>
      </ShowMore>
    </Content>
  ) : null;

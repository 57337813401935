import { JobUserXingApplicationStatus } from '@xing-com/crate-common-graphql-types';
import { SUPPORTED_SUMMARY_LANGUAGES } from '@xing-com/crate-jobs-constants';
import type { JobDetailsVisibleJobFragment } from '@xing-com/crate-jobs-graphql';

import { getEmployerData } from './get-employer-data';
import { getMatchingJobAgeKey } from './get-matching-job-age-key';
import type {
  ApplicationData,
  CompanyData,
  JobDetails,
  JobFactsData,
  JobHighlights,
  JobLocationData,
  SearchAlertFilters,
  SummaryData,
} from './job-details-types';

export const selectJobDetails = (
  job: JobDetailsVisibleJobFragment
): JobDetails => {
  const {
    activatedAt,
    activeUntil,
    refreshedAt,
    application: jobApplication,
    banner,
    careerLevel,
    companyInfo,
    description,
    discipline,
    employmentType,
    id,
    jobCode,
    keywords,
    language,
    location,
    matchingHighlights,
    paid,
    prioritized,
    remoteOptions,
    redirectsToThirdPartyUrl,
    salary,
    serviceOfferingGroup,
    slug,
    summary,
    title,
    userInteractions,
    topJob,
    video,
  } = job;

  const application: ApplicationData = {
    applyEmail:
      jobApplication?.__typename === 'EmailApplication'
        ? (jobApplication?.applyEmail ?? undefined)
        : undefined,
    applyUrl:
      jobApplication?.__typename === 'UrlApplication'
        ? (jobApplication?.applyUrl ?? undefined)
        : undefined,
    code: jobCode ?? undefined,
    contactUserId:
      jobApplication?.__typename === 'MessageApplication'
        ? (jobApplication.contactUserId ?? undefined)
        : undefined,
    jobId: id,
    prioritized,
    slug,
    status: userInteractions?.application?.status,
    submittedAt:
      userInteractions?.application?.status !==
        JobUserXingApplicationStatus.Error &&
      userInteractions?.application?.status !==
        JobUserXingApplicationStatus.Undelivered
        ? userInteractions?.application?.createdAt
        : undefined,
    title,
    type: jobApplication?.__typename,
  };

  const { company, companyNameOverride } = companyInfo;

  const companyData: CompanyData = {
    id: company?.id ?? '',
    name: companyNameOverride,
    link: company?.links?.public ?? undefined,
    logo: company?.logos?.x1 ?? undefined,
    country: company?.address?.country?.localizationValue,
    city: company?.address?.city ?? undefined,
    industry: company?.industry?.localizationValue,
    companySize: company?.companySize ?? undefined,
    isFollowing: company?.userContext?.followState?.isFollowing ?? false,
  };

  const facts: JobFactsData = {
    employmentType: employmentType
      ? {
          id: employmentType.id,
          name: employmentType.localizationValue,
        }
      : undefined,
    isVerifiedByXing: paid && !redirectsToThirdPartyUrl,
    location: location?.city ?? undefined,
  };

  const highlights: JobHighlights = {
    isApplyWithoutDocuments: application.type === 'JobXingApplication',
    isMatchingSalary: !!matchingHighlights?.matchingFacts.some(
      ({ type }) => type === 'job_keyfact_salary'
    ),
    remoteOption:
      !!remoteOptions && remoteOptions.length === 1 && !!remoteOptions[0]
        ? remoteOptions[0]
        : undefined,
    matchingJobAgeKey: getMatchingJobAgeKey(activatedAt, activeUntil),
    earnUpTo:
      matchingHighlights?.highlight?.type === 'job_highlight_earn_more'
        ? matchingHighlights.highlight.localization.localizationValue
        : undefined,
  };

  const locationData: JobLocationData = {
    street: location?.street ?? undefined,
    city: location?.city ?? undefined,
    cityId: location?.cityId ?? undefined,
    region: location?.region ?? undefined,
    zipCode: location?.zipCode ?? undefined,
    country: location?.country?.localizationValue ?? undefined,
    countryCode: location?.country?.countryCode ?? undefined,
  };

  const salaryData =
    redirectsToThirdPartyUrl && salary?.__typename === 'SalaryEstimate'
      ? undefined
      : (salary ?? undefined);

  const searchAlertFilters: SearchAlertFilters = {
    filter: {
      discipline: {
        id: [discipline?.id ?? null],
        localizationValue: discipline?.localizationValue ?? '',
      },
    },
    location: { text: location?.city ?? undefined, radius: 20 },
  };

  const summaryData: SummaryData | undefined =
    !!summary && (!language || SUPPORTED_SUMMARY_LANGUAGES.includes(language))
      ? {
          language: language ?? undefined,
          summary: summary ?? undefined,
        }
      : undefined;

  const jobKeywords = (keywords || []).filter(
    (keyword): keyword is string => !!keyword
  );

  return {
    activatedAt: activatedAt ?? undefined,
    activeUntil: activeUntil ?? undefined,
    refreshedAt,
    application,
    banner: banner?.image936x200 ?? undefined,
    bookmarkState: userInteractions?.bookmark?.state,
    careerLevel: careerLevel
      ? {
          id: parseInt(careerLevel.id, 10),
          name: careerLevel.localizationValue,
        }
      : undefined,
    company: companyData,
    description,
    discipline: discipline
      ? {
          id: parseInt(discipline.id, 10),
          name: discipline.localizationValue,
        }
      : undefined,
    employerData: getEmployerData(company, companyNameOverride),
    facts,
    globalId: job.globalId,
    highlights,
    id,
    industry: job.industry
      ? {
          id: parseInt(job.industry.id, 10),
          name: job.industry.localizationValue,
        }
      : undefined,
    jobCode,
    keywords: jobKeywords,
    location: locationData,
    paid,
    redirectsToThirdPartyUrl,
    salary: salaryData,
    searchAlertFilters,
    slug,
    summaryData,
    title,
    url: job.url,
    videoUrl: video?.url ?? undefined,
    // Tracking
    prioritized,
    serviceOfferingGroup,
    topJob,
  };
};

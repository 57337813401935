import styled from 'styled-components';

import type { SimilarJobData } from '@xing-com/crate-jobs-helpers';
import { spaceL } from '@xing-com/tokens';

import { ModuleTitle } from '../module-title';
import { SimilarJob } from '../similar-job';

import { SimilarJobsLoading } from './similar-jobs-loading';

type SimilarJobsSidebarProps = {
  similarJobs: SimilarJobData[];
  isLoading: boolean;
};

const SimilarJobsSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceL};
`;

export const SimilarJobsSidebar: React.FC<SimilarJobsSidebarProps> = ({
  similarJobs,
  isLoading,
}) => {
  if (isLoading)
    return (
      <SimilarJobsSidebarContent>
        <SimilarJobsLoading count={4} />
      </SimilarJobsSidebarContent>
    );

  if (similarJobs.length === 0) return null;

  return (
    <SimilarJobsSidebarContent>
      <ModuleTitle titleKey="JOBS_JOB_DETAILS_SIMILAR_JOBS_HEADLINE" />
      {similarJobs.map((similarJob) => (
        // wrap in a div to maintain the flex gap and avoid issues with the
        // InViewTracking component
        <div key={similarJob.job.id}>
          <SimilarJob similarJob={similarJob} isInSidebar ijtParam="jb_21" />
        </div>
      ))}
    </SimilarJobsSidebarContent>
  );
};

import type React from 'react';
import { FormattedMessage, FormattedNumber, FormattedPlural } from 'react-intl';
import styled from 'styled-components';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { PropTrackActionData } from '@xing-com/crate-jobs-hooks';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import { Hyperlink } from '@xing-com/hyperlink';

const StyledLink = styled(Hyperlink)`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

type Props = {
  ratingCount: number;
  kununuLink: string;
  jobId: string;
};

export const KununuReviews: React.FC<Props> = ({
  ratingCount,
  kununuLink,
  jobId,
}) => {
  const { track } =
    useTracking<InteractionTrackingEvent<PropTrackActionData>>();
  const trackNwtEvent = useJobsNwtTracking();

  const values = {
    ratingCount: (
      <strong>
        <FormattedNumber value={ratingCount} />
      </strong>
    ),
  };
  const kununuTrackingLink = `${kununuLink}?utm_source=xing_jobs&utm_medium=affiliate&utm_content=cta_score_profile&rfr=xjp_cta_score_profile`;

  const onKununuLinkClick = (): void => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'jobs_kununu_module_view_ratings_link_click',
    });

    trackNwtEvent({
      event: 'visited',
      eventSchema: 'basic',
      page: 'jobs/details',
      element: 'kununu_module_view_ratings_link',
      itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
    });
  };

  return (
    <StyledLink
      // @ts-expect-error FIXME: SC6
      onClick={onKununuLinkClick}
      href={kununuTrackingLink}
      target="_blank"
      rel="noopener"
      external
    >
      <FormattedPlural
        value={ratingCount}
        one={
          <FormattedMessage
            id="JOBS_JOB_DETAILS_EMPLOYEE_REVIEW"
            values={values}
          />
        }
        other={
          <FormattedMessage
            id="JOBS_JOB_DETAILS_EMPLOYEE_REVIEW"
            values={values}
          />
        }
      />
    </StyledLink>
  );
};

import type { SocialUrlsData } from '@xing-com/crate-jobs-helpers';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import {
  IconButterflyMono,
  IconCompaniesFilled,
  IconFacebook,
  IconInstagram,
  IconKununu,
  IconPinterest,
  IconTiktok,
  IconTwitter,
  IconYoutube,
} from '@xing-com/icons';

import { SocialLinkButton, SocialLinksWrapper } from './social-links.styles';

type Service = keyof SocialUrlsData;

type SocialLinkProps = {
  jobId: string;
  url: string;
  service: Service;
};

type SocialLinksProps = {
  jobId: string;
  socialUrls: SocialUrlsData;
};

const SERVICE_ICON: Record<Service, React.FC> = {
  website: IconCompaniesFilled,
  facebook: IconFacebook,
  twitter: IconTwitter,
  youtube: IconYoutube,
  xing: IconButterflyMono,
  instagram: IconInstagram,
  pinterest: IconPinterest,
  tiktok: IconTiktok,
  kununu: IconKununu,
};

const isSupportedService = (service: string): service is Service => {
  return Object.keys(SERVICE_ICON).includes(service);
};

const isValidLink = (
  entry: [string, string | null]
): entry is [Service, string] => isSupportedService(entry[0]) && !!entry[1];

const SocialLink: React.FC<SocialLinkProps> = ({ jobId, url, service }) => {
  const trackNwtEvent = useJobsNwtTracking();

  return (
    <SocialLinkButton
      icon={SERVICE_ICON[service]}
      href={url}
      // @ts-expect-error FIXME: SC6
      target="_blank"
      rel="nofollow noopener"
      aria-label={service}
      onClick={(event) => {
        // Stop propagation so this event doesn't register as a description link
        // click event
        event.stopPropagation();

        trackNwtEvent({
          eventSchema: 'basic',
          page: 'jobs/detail',
          event: service === 'xing' ? 'opened' : 'visited',
          element: 'job_description_social_target',
          itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
        });
      }}
    />
  );
};

export const SocialLinks: React.FC<SocialLinksProps> = ({
  jobId,
  socialUrls,
}) => {
  const validLinks = Object.entries(socialUrls).filter(isValidLink);

  if (!validLinks.length) return null;

  return (
    <SocialLinksWrapper>
      {validLinks.map(([service, url]) => {
        return (
          <SocialLink key={service} jobId={jobId} service={service} url={url} />
        );
      })}
    </SocialLinksWrapper>
  );
};

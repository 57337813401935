import styled from 'styled-components';

import type { HtmlDescription as HtmlDescriptionType } from '@xing-com/crate-common-graphql-types';

import { ShowMore } from '../../show-more';
import { DESCRIPTION_PREVIEW_HEIGHT } from '../constants';
import { useLinkTracking } from '../use-link-tracking';

type Props = {
  jobId: string;
  description: HtmlDescriptionType;
  skipShowMore: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
};

const DescriptionContainer = styled.div`
  line-height: normal;
  word-break: break-word;
`;

export const HtmlDescription: React.FC<Props> = ({
  jobId,
  description,
  skipShowMore,
  onExpand,
  onCollapse,
}) => {
  const trackingContainerRef = useLinkTracking(jobId);

  return skipShowMore ? (
    <DescriptionContainer
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dangerouslySetInnerHTML={{ __html: description.content as TrustedHTML }}
      ref={trackingContainerRef}
    />
  ) : (
    <ShowMore
      previewHeight={DESCRIPTION_PREVIEW_HEIGHT}
      expandLabelKey="JOBS_JOB_DETAILS_DESCRIPTION_SHOW_MORE"
      collapseLabelKey="JOBS_JOB_DETAILS_DESCRIPTION_SHOW_LESS"
      onExpand={onExpand}
      onCollapse={onCollapse}
      hideOnDesktop
      centered
    >
      <DescriptionContainer
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        dangerouslySetInnerHTML={{ __html: description.content as TrustedHTML }}
        ref={trackingContainerRef}
      />
    </ShowMore>
  );
};

/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetJobDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type GetJobDetailsQuery = {
  __typename?: 'Query';
  job?:
    | {
        __typename?: 'UnauthorizedJob';
        id: string;
        reason: Types.UnauthorizedJobReason;
      }
    | {
        __typename?: 'VisibleJob';
        activeUntil?: string | null;
        jobCode?: string | null;
        keywords: Array<string | null>;
        language?: string | null;
        paid: boolean;
        prioritized: boolean;
        redirectsToThirdPartyUrl: boolean;
        remoteOptions?: Array<Types.JobRemoteOptionValue | null> | null;
        serviceOfferingGroup: number;
        topJob: boolean;
        id: string;
        slug: string;
        url: string;
        title: string;
        activatedAt?: string | null;
        refreshedAt: string;
        globalId: string;
        application?:
          | { __typename: 'EasyApplication' }
          | { __typename: 'EmailApplication'; applyEmail?: any | null }
          | { __typename: 'JobXingApplication' }
          | { __typename: 'MessageApplication'; contactUserId?: string | null }
          | { __typename: 'UrlApplication'; applyUrl?: string | null }
          | null;
        banner?: {
          __typename?: 'JobBanner';
          image936x200?: string | null;
        } | null;
        careerLevel?: {
          __typename?: 'CareerLevel';
          id: string;
          localizationValue: string;
        } | null;
        companyInfo: {
          __typename?: 'JobCompanyInfo';
          companyNameOverride: string;
          company?: {
            __typename?: 'Company';
            id: string;
            companySize?: string | null;
            logos?: {
              __typename?: 'CompanyLogos';
              logo96px?: string | null;
              x1?: string | null;
            } | null;
            kununuData?: {
              __typename?: 'CompanyKununuData';
              ratingAverage?: number | null;
              companyProfileUrl?: string | null;
              ratingCount?: number | null;
              mappedBenefits?: Array<{
                __typename?: 'CompanyMappedBenefit';
                type: Types.CompanyMappedBenefitType;
              } | null> | null;
              culture?: {
                __typename?: 'CompanyKununuCulture';
                link?: string | null;
                completedSubmissionsCount?: number | null;
                cultureCompass?: {
                  __typename?: 'CompanyKununuCultureDimension';
                  profile?: number | null;
                  industry?: number | null;
                } | null;
              } | null;
            } | null;
            links?: {
              __typename?: 'CompanyLinks';
              public?: string | null;
            } | null;
            industry?: {
              __typename?: 'Industry';
              localizationValue: string;
            } | null;
            address?: {
              __typename?: 'CompanyAddress';
              city?: string | null;
              country?: {
                __typename?: 'Country';
                localizationValue: string;
              } | null;
            } | null;
            userContext?: {
              __typename?: 'CompanyUserContext';
              followState?: {
                __typename?: 'CompanyFollowState';
                isFollowing?: boolean | null;
              } | null;
            } | null;
          } | null;
        };
        description:
          | {
              __typename?: 'ExternalUrlDescription';
              url: string;
              proxyUrl: string;
              htmlContent: string;
            }
          | { __typename?: 'HtmlDescription'; content?: string | null }
          | { __typename?: 'PdfDescription' }
          | {
              __typename?: 'TemplateData';
              sectionTitleColor?: string | null;
              genericDescription?: string | null;
              companyDescriptionTitle?: string | null;
              companyDescriptionContent?: string | null;
              responsibilityTitle?: string | null;
              responsibilityContent?: string | null;
              skillsTitle?: string | null;
              skillsContent?: string | null;
              weOfferTitle?: string | null;
              weOfferContent?: string | null;
              contactInfoTitle?: string | null;
              contactInfoContent?: string | null;
              headerImage?: string | null;
              footerImage?: string | null;
              videoUrls?: {
                __typename?: 'TemplateDataVideoUrls';
                youtube?: string | null;
                vimeo?: string | null;
              } | null;
              socialUrls?: {
                __typename?: 'TemplateDataSocialUrls';
                website?: string | null;
                facebook?: string | null;
                twitter?: string | null;
                youtube?: string | null;
                xing?: string | null;
                instagram?: string | null;
                pinterest?: string | null;
                tiktok?: string | null;
                kununu?: string | null;
              } | null;
            };
        discipline?: {
          __typename?: 'Discipline';
          id: string;
          localizationValue: string;
        } | null;
        employmentType?: {
          __typename?: 'EmploymentType';
          id: string;
          localizationValue: string;
        } | null;
        industry?: {
          __typename?: 'Industry';
          id: string;
          localizationValue: string;
        } | null;
        location?: {
          __typename?: 'JobLocation';
          street?: string | null;
          city?: string | null;
          cityId?: number | null;
          zipCode?: string | null;
          region?: string | null;
          country?: {
            __typename?: 'Country';
            countryCode: Types.CountryCodesAlpha2;
            localizationValue: string;
          } | null;
        } | null;
        matchingHighlights?: {
          __typename?: 'JobMatchingHighlightsV2';
          highlight?: {
            __typename?: 'JobMatchingHighlightsJobHighlightV2';
            type: string;
            localization: {
              __typename?: 'JobMatchingHighlightsLocalizableV2';
              localizationValue: string;
            };
          } | null;
          matchingFacts: Array<
            | {
                __typename?: 'JobMatchingHighlightsJobKeyfactIntV2';
                type: string;
              }
            | {
                __typename?: 'JobMatchingHighlightsJobKeyfactSalaryV2';
                type: string;
              }
            | {
                __typename?: 'JobMatchingHighlightsJobKeyfactStringV2';
                type: string;
              }
          >;
        } | null;
        salary?:
          | {
              __typename?: 'Salary';
              currency?: Types.Currency | null;
              amount?: number | null;
            }
          | {
              __typename?: 'SalaryEstimate';
              currency?: Types.Currency | null;
              minimum?: number | null;
              maximum?: number | null;
              median?: number | null;
            }
          | {
              __typename?: 'SalaryRange';
              currency?: Types.Currency | null;
              minimum?: number | null;
              maximum?: number | null;
            }
          | { __typename?: 'SalaryUnauthorized' }
          | null;
        summary?: {
          __typename?: 'JobSummary';
          trackingToken: string;
          content: {
            __typename?: 'JobSummaryContent';
            introduction: string;
            qualifications: Array<string>;
            keyResponsibilities: Array<string>;
          };
        } | null;
        userInteractions?: {
          __typename?: 'JobUserInteractions';
          application?: {
            __typename?: 'JobUserXingApplication';
            createdAt: string;
            status: Types.JobUserXingApplicationStatus;
          } | null;
          bookmark?: {
            __typename?: 'JobBookmark';
            state: Types.JobBookmarkState;
          } | null;
        } | null;
        video?: { __typename?: 'JobVideo'; url: string } | null;
      }
    | null;
};

export const GetJobDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableTemplateData' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UnauthorizedJob' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reason' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobDetailsVisibleJob' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Salary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Salary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryRange' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryRange' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalaryEstimate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalaryEstimate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VisibleJobCommon' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'globalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'x1' },
                              name: { kind: 'Name', value: 'logo96px' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'kununuData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ratingAverage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KununuData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompanyKununuData' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'companyProfileUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mappedBenefits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ratingAverage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ratingCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'culture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedSubmissionsCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cultureCompass' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profile' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Company' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'links' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'logo96px' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFollowing' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kununuData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'KununuData' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialUrls' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TemplateDataSocialUrls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facebook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'youtube' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinterest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tiktok' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kununu' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Description' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDescription' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HtmlDescription' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExternalUrlDescription' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'proxyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'htmlContent' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TemplateData' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sectionTitleColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'genericDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyDescriptionTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyDescriptionContent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilityTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilityContent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'skillsTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skillsContent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weOfferTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weOfferContent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfoTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactInfoContent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headerImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'footerImage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'youtube' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'vimeo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SocialUrls' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobDetailsVisibleJob' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VisibleJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VisibleJobCommon' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activeUntil' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'application' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UrlApplication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'EmailApplication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyEmail' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MessageApplication' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactUserId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'banner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image936x200' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careerLevel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Company' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Description' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discipline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'localizationValue' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'jobCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'matchingHighlights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: { kind: 'EnumValue', value: 'postings' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highlight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localizationValue',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'matchingFacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'paid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prioritized' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redirectsToThirdPartyUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteOptions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Salary' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryRange' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalaryEstimate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceOfferingGroup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'summary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackingToken' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'introduction' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualifications' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyResponsibilities' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'topJob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userInteractions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobUserXingApplication',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bookmark' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetJobDetailsQuery, GetJobDetailsQueryVariables>;

import { useQuery } from '@apollo/client';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { GetSimilarJobsDocument } from '@xing-com/crate-jobs-graphql';
import type { SimilarJobData } from '@xing-com/crate-jobs-helpers';

const LI_CONSUMER = 'loggedin.web.jobs.posting.similar';
const LO_CONSUMER = 'loggedout.web.jobs.posting.similar';

type SimilarJobsResponse = {
  loading: boolean;
  similarJobs: SimilarJobData[];
};

type Props = { limit: number; jobId?: string; skip?: boolean };

export const useSimilarJobs = ({
  limit,
  jobId,
  skip = false,
}: Props): SimilarJobsResponse => {
  const { isLoggedIn } = useLoginState();
  const { loading, error, data } = useQuery(GetSimilarJobsDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      jobId: jobId as string,
      limit,
      consumer: isLoggedIn ? LI_CONSUMER : LO_CONSUMER,
    },
    skip: skip || !jobId,
  });

  if (error) return { loading: false, similarJobs: [] };

  const similarJobs = (data?.viewer?.similarJobs?.collection ?? []).reduce<
    SimilarJobData[]
  >((arr, collectionItem): SimilarJobData[] => {
    if (collectionItem.__typename !== 'JobRecommendationsSimilarJob')
      return arr;

    const { job, trackingToken, position } = collectionItem;

    if (job?.__typename === 'VisibleJob')
      return [
        ...arr,
        {
          job,
          trackingToken,
          position,
        },
      ];

    return arr;
  }, []);

  return {
    loading,
    similarJobs,
  };
};

import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatSalary } from '@xing-com/crate-jobs-helpers';
import type { SalaryValid } from '@xing-com/crate-jobs-helpers';

import { ModuleTitle } from '../module-title';

import { SalaryForecastBar } from './salary-forecast-bar';
import { BodyCopy } from './salary-module.common.styles';

type Props = {
  salary: SalaryValid;
};

export const SalaryExact: React.FC<Props> = ({ salary }) => {
  const intl = useIntl();

  return (
    <>
      <ModuleTitle titleKey="JOBS_JOB_DETAIL_SALARY_EXACT_HEADLINE" noMargin />
      <BodyCopy>
        <FormattedMessage id="JOBS_JOB_DETAIL_SALARY_EXACT_BODY" />
      </BodyCopy>
      <SalaryForecastBar headlineCopy={formatSalary(intl, salary)} />
    </>
  );
};
